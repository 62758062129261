import React, { useCallback, useEffect, useState } from "react";
import {
  Container, Grid, Card, CardMedia, CardContent, Typography, Box, Paper, Button
} from '@mui/material';
import { useCosmWasmClient, useWallet } from "@sei-js/react";
import { useNftOwnership } from '../context/NftOwnershipContext';
import { FaTwitter, FaDiscord } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SeiLogo from '../assets/sei-logo';
import '@fortawesome/fontawesome-free/css/all.min.css';

const MUSIC_CONTRACT_ADDRESS = "sei17ha72tfyvkrxjh0wlvagjq25petfgsa6qphw324e703w8mdffxzsjlhjpf"; 
const GENESIS_CONTRACT_ADDRESS = "sei1xfanx5rqj3w4mlcgg6prresh9n4rqc90mqtz82ylx0e8j2wpnlmq5vxxaf";
const SEI_CHAIN_EXPLORER_URL = "https://www.seiscan.app/pacific-1/contracts/";

const Home = () => {
  const [nfts, setNfts] = useState([]);
  const { setHasNfts } = useNftOwnership();
  const [collectionStats, setCollectionStats] = useState({});
  const [useGenesis, setUseGenesis] = useState(true);
  const { accounts } = useWallet();
  const { cosmWasmClient: queryClient } = useCosmWasmClient();

  const currentContractAddress = useGenesis ? GENESIS_CONTRACT_ADDRESS : MUSIC_CONTRACT_ADDRESS;

  const fetchNFTs = useCallback(async () => {
    if (!queryClient || accounts[0]?.address === undefined) return;

    const nftQuery = { tokens: { owner: accounts[0].address } };
    const nftIds = await queryClient.queryContractSmart(currentContractAddress, nftQuery);

    const metadataPromises = nftIds.tokens.map(async (tokenId) => {
      const metadataUri = await queryClient.queryContractSmart(currentContractAddress, { nft_info: { token_id: tokenId } });
      const response = await fetch(metadataUri.token_uri);
      const metadata = await response.json();
      return metadata;
    });

    const fetchedNfts = await Promise.all(metadataPromises);
    setNfts(fetchedNfts);
    setHasNfts(fetchedNfts.length > 0);

  }, [queryClient, accounts, currentContractAddress, setHasNfts]);

  const fetchCollectionStats = useCallback(async () => {
    try {
      const response = await fetch(`https://api.pallet.exchange/api/v2/nfts/${currentContractAddress}/details`);
      const stats = await response.json();
      setCollectionStats(stats);
    } catch (err) {
      console.error("Failed to fetch collection stats:", err);
      setCollectionStats({});
    }
  }, [currentContractAddress]);

  useEffect(() => {
    fetchNFTs();
    fetchCollectionStats();
  }, [fetchNFTs, fetchCollectionStats, currentContractAddress]);

  const handleMouseEnter = (e) => e.target.style.textDecoration = "underline";
  const handleMouseLeave = (e) => e.target.style.textDecoration = "none";

  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      <Container
        maxWidth="lg"
        style={{ paddingTop: "80px", paddingBottom: "20px" }}
      >
        {/* Tab Switch */}
        <Box display="flex" justifyContent="center" mb="10px">
          <Button
            className={`tab-button ${useGenesis ? "selected-tab" : ""}`}
            onClick={() => setUseGenesis(true)}
          >
            Genesis
          </Button>
          <Button
            className={`tab-button ${useGenesis ? "" : "selected-tab"}`}
            onClick={() => setUseGenesis(false)}
          >
            Music
          </Button>
        </Box>

        {/* Statistics Banner */}
        {collectionStats && Object.keys(collectionStats).length > 0 ? (
          <Box style={{ backgroundColor: "rgba(44, 44, 44, 0.3)", padding: "20px", borderRadius: "10px", marginBottom: "20px" }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb="10px">
              {/* Left Info */}
              <Box>
                <Typography variant="h5" style={{ color: "ivory", marginBottom: "5px" }}>
                  {collectionStats.name}
                </Typography>
                <Typography variant="body2" style={{ color: "#B0B0B0", marginBottom: "5px" }}>
                  {collectionStats.description}
                </Typography>

                {/* Contract Info */}
                <Box display="flex" alignItems="center">
                  <Typography variant="body2" style={{ color: "royalblue" }}>
                    Contract: 
                  </Typography>
                  <Typography variant="body2" style={{ color: "ivory", marginLeft: "5px" }}>
                    <a
                      href={`${SEI_CHAIN_EXPLORER_URL}${currentContractAddress}`}
                      target="_blank" rel="noopener noreferrer"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      style={{ textDecoration: "none", color: "ivory" }}
                    >
                      {currentContractAddress.substring(0, 5)}...{currentContractAddress.substring(currentContractAddress.length - 4)}
                    </a>
                  </Typography>
                  <CopyToClipboard text={currentContractAddress}>
                    <Button style={{ marginLeft: "-15px" }}>
                      <i className="fas fa-copy" />  {/* Font Awesome copy icon */}
                    </Button>
                  </CopyToClipboard>
                </Box>
              </Box>

              {/* Right Stats */}
              <Box display="flex" gap="10px">
                {collectionStats.floor && (
                  <Paper style={{ backgroundColor: "rgba(31, 31, 31, 0.5)", padding: "10px", borderRadius: "5px" }}>
                    <Typography variant="h6" style={{ color: "royalblue" }}>
                      Floor Price
                    </Typography>
                    <Typography variant="h5" style={{ color: "ivory", textAlign: "center" }}>
                      {collectionStats.floor.toFixed(1)} <SeiLogo />
                    </Typography>
                  </Paper>
                )}
                {collectionStats.volume && (
                  <Paper style={{ backgroundColor: "rgba(31, 31, 31, 0.5)", padding: "10px", borderRadius: "5px" }}>
                    <Typography variant="h6" style={{ color: "royalblue" }}>
                      Total Volume
                    </Typography>
                    <Typography variant="h5" style={{ color: "ivory", textAlign: "center" }}>
                      {collectionStats.volume.toFixed(1)} <SeiLogo />
                    </Typography>
                  </Paper>
                )}
                {collectionStats.supply && (
                  <Paper style={{ backgroundColor: "rgba(31, 31, 31, 0.5)", padding: "10px", borderRadius: "5px" }}>
                    <Typography variant="h6" style={{ color: "royalblue" }}>
                      Items
                    </Typography>
                    <Typography variant="h5" style={{ color: "ivory", textAlign: "center" }}>
                      {collectionStats.supply}
                    </Typography>
                  </Paper>
                )}
                {collectionStats.auction_count && (
                  <Paper style={{ backgroundColor: "rgba(31, 31, 31, 0.5)", padding: "10px", borderRadius: "5px" }}>
                    <Typography variant="h6" style={{ color: "royalblue" }}>
                      For Sale
                    </Typography>
                    <Typography variant="h5" style={{ color: "ivory", textAlign: "center" }}>
                      {collectionStats.auction_count}
                    </Typography>
                  </Paper>
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <Typography variant="h6" style={{ color: "ivory" }}>
            Unable to load collection statistics.
          </Typography>
        )}

        {/* Gallery Section */}
        {nfts.length > 0 ? (
          <Box className="gallery-container">
            <Grid container spacing={2} style={{ justifyContent: "center" }}>
              {nfts.map((nft, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
                  <Card
                    style={{ border: "2px solid royalblue", backgroundColor: "rgba(51, 51, 51, 0.3)", color: "ivory" }}
                  >
                    {/* Video or Image */}
                    {nft.image.endsWith('.mp4') ? (
                      <video controls style={{ width: "100%", height: "100%" }}>
                        <source src={nft.image} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <CardMedia
                        component="img"
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        src={nft.image}
                        alt={nft.name}
                      />
                    )}

                    <CardContent>
                      <Typography variant="h8" style={{ fontWeight: "bold" }}>
                        {nft.name}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Box style={{ color: "white", fontWeight: "bold", textAlign: "center", marginTop: "20px" }}>
            <Typography variant="h6">
              No NFTs found in this collection.
            </Typography>
            <Button 
              variant="contained"
              color="primary"
              onClick={() => window.open(useGenesis ? 
                "https://pallet.exchange/collection/straight-outta-sei" : 
                "https://pallet.exchange/collection/sos-studios", "_blank")}
              style={{ marginTop: "20px" }}
            >
              Buy NFT
            </Button>
          </Box>
        )}
      </Container>

      {/* Social Media Section */}
      <Box style={{ position: "fixed", bottom: 0, width: "100%", display: "flex", justifyContent: "center", backgroundColor: "#111", padding: "10px 0" }}>
        <a href="https://twitter.com/StraightOutaSei" target="_blank" rel="noopener noreferrer" style={{ color: "#00acee", marginRight: "10px" }}>
          <FaTwitter size={24} />
        </a>
        <a href="https://discord.gg/rcHpYGjQsd" target="_blank" rel="noopener noreferrer" style={{ color: "#7289da" }}>
          <FaDiscord size={24} />
        </a>
      </Box>
    </div>
  );
};

export default Home;