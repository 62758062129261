import React from 'react';

function GameCollab() {
  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <iframe 
        src="/game-collab/index.html" 
        title="Unity Game" 
        width="100%" 
        height="100%"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default GameCollab;