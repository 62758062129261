import { AppBar, Toolbar, Button, Box, Typography, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useWallet, WalletConnectButton } from '@sei-js/react';
import { useNftOwnership } from '../context/NftOwnershipContext';
import './Navbar.css';

function Navbar() {
  const { hasNfts } = useNftOwnership();
  const navigate = useNavigate();
  const { connectedWallet, accounts, disconnect } = useWallet();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleGameCollabClick = () => {
    if (hasNfts) {
      navigate("/game-collab");
    } else {
      alert("You need to own an NFT from our collection to access this page.");
    }
  };

  const handleWalletMenuClick = (event) => setAnchorEl(event.currentTarget);
  const handleWalletMenuClose = () => setAnchorEl(null);
  const handleDisconnect = () => {
    disconnect();
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed" className="navbar">
      <Toolbar className="navbar-toolbar">
        <Box display="flex" alignItems="center">
          <img
            src="https://arweave.net/ZIq2rKW4AFCAeLSNOUEIFnJ9lCOIr9yc0GKCUkX1HiQ/-1.png"
            alt="Logo"
            style={{ width: "50px", height: "50px", marginRight: "10px" }}
          />
          <Typography variant="h6" className="navbar-title">
            SOS Studios
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button className="navbar-item" onClick={() => navigate("/")}>
            Dashboard
          </Button>
          {/*
          <Button className="navbar-item" onClick={() => navigate("/upload")}>
            Upload Collection
          </Button>
          */}
          <a href="/typescript-page/index.html" target="_blank" style={{ textDecoration: 'none' }}>
            <Button className="navbar-item">
              Mint
            </Button>
          </a>
          <Button className="navbar-item" onClick={handleGameCollabClick}>
            Game Collab
          </Button>
          <div className="wallet-connect-button">
            { !connectedWallet && <WalletConnectButton /> }
          </div>

          {connectedWallet && (
            <>
              <Button
                className="navbar-item wallet-address"
                onClick={handleWalletMenuClick}
              >
                {`${accounts[0].address.substring(0, 5)}...${accounts[0].address.substring(
                  accounts[0].address.length - 5
                )}`}
              </Button>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleWalletMenuClose}
              >
                <MenuItem onClick={handleDisconnect}>Disconnect</MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
