import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { SeiWalletProvider } from "@sei-js/react";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
//import UploadCollection from "./pages/UploadCollection";
import RequireWalletConnection from './components/RequireWalletConnection';
import { NftOwnershipProvider } from './context/NftOwnershipContext';
import GameCollab from './components/GameCollab';
import "./App.css";

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: '#333', 
          color: 'ivory', 
          borderRadius: '4px', 
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'ivory', 
        },
      },
    },
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <SeiWalletProvider
          chainConfiguration={{
            chainId: "pacific-1",
            restUrl: "https://rest.sei-apis.com",
            rpcUrl: "https://rpc.sei-apis.com",
          }}
          wallets={["compass", "fin"]}
        >
          <NftOwnershipProvider>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              { 
              //<Route path="/upload" element={<RequireWalletConnection><UploadCollection /></RequireWalletConnection>} /> 
              }
              <Route path="/game-collab" element={<RequireWalletConnection><GameCollab /></RequireWalletConnection>} />
            </Routes>
          </NftOwnershipProvider>
        </SeiWalletProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;