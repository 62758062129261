import React from 'react';
import { Navigate } from 'react-router-dom';
import { useWallet } from '@sei-js/react';

const RequireWalletConnection = ({ children }) => {
  const { connectedWallet } = useWallet();

  if (!connectedWallet) {
    return <Navigate to="/" replace />; 
  }

  return children;
};

export default RequireWalletConnection; 
