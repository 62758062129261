import React, { createContext, useContext, useState } from 'react';

const NftOwnershipContext = createContext();

export const useNftOwnership = () => useContext(NftOwnershipContext);

export const NftOwnershipProvider = ({ children }) => {
  const [hasNfts, setHasNfts] = useState(false);

  return (
    <NftOwnershipContext.Provider value={{ hasNfts, setHasNfts }}>
      {children}
    </NftOwnershipContext.Provider>
  );
};