import React from 'react';

const SeiLogo = () => {
  return (
    <svg width="12" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.9694 24C15.5343 24 18.737 22.4534 20.94 19.9962C19.9153 19.1078 18.3709 19.0539 17.2799 19.932L17.0715 20.0997C15.0739 21.7074 12.1633 21.47 10.4541 19.5599C9.52196 18.5182 7.92554 18.4118 6.86275 19.3206L4.45968 21.3755C6.51788 23.0179 9.12866 24 11.9694 24ZM15.8701 18.1892C17.7727 16.6578 20.447 16.7128 22.2769 18.1918C23.3707 16.3841 24 14.2654 24 12C24 9.48279 23.223 7.14666 21.8952 5.21712C21.0392 5.03528 20.1113 5.24766 19.407 5.8702L19.2065 6.04739C17.2855 7.7454 14.367 7.6422 12.5713 5.81283C11.5919 4.8151 9.99224 4.78232 8.97264 5.73913L6.17618 8.36329L4.63782 6.7323L7.43428 4.10812C9.34424 2.31579 12.3408 2.37716 14.1754 4.24619C15.134 5.22277 16.6921 5.27786 17.7176 4.3714L17.918 4.19421C18.5405 3.64399 19.2608 3.27305 20.0148 3.07796C17.8834 1.16449 15.0628 0 11.9694 0C5.86222 0 0.817903 4.53908 0.0422494 10.4195C1.89725 9.53161 4.18537 9.87327 5.69787 11.4182C6.6527 12.3935 8.19015 12.4964 9.26711 11.6572L10.8307 10.4388C12.7956 8.90753 15.5734 8.98164 17.4535 10.6155L20.4976 13.2609L19.0228 14.9493L15.9788 12.304C14.9097 11.3749 13.3302 11.3327 12.2128 12.2035L10.6493 13.4218C8.67059 14.9638 5.84589 14.7746 4.09161 12.9827C3.07151 11.9407 1.4031 11.9044 0.33835 12.901L0 13.2176C0.253212 15.724 1.27965 18.0027 2.83775 19.8129L5.40148 17.6205C7.39238 15.918 10.3829 16.1173 12.1291 18.0688C13.0415 19.0885 14.5953 19.2152 15.6617 18.357L15.8701 18.1892Z" fill="#EC5D42"></path>
    </svg>
  );
};

export default SeiLogo;